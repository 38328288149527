<template>
  <div class="background">

<!-- Header -->
    <div class="header">
      <local-capability-banner :pagename=pagename /> <!-- Links to src/global/Banner.vue -->
    </div>

<!-- Main body -->
        <v-container class=cont1 fluid>
              <local-capability-card-right capability='migration' /> <!-- Links to src/local/capability/card-right.vue -->
              <local-capability-card-left capability='fullstack' /> <!-- Links to src/local/capability/card-left.vue -->
        </v-container>
      
      <v-container class=cont2 fluid>
          <local-capability-card-right capability='infrastructure' /> <!-- Links to src/local/capability/card-right.vue -->
          <local-capability-card-left capability='mobility' /> <!-- Links to src/local/capability/card-right.vue -->
      </v-container>
      
        <v-container class=cont3 fluid>
              <local-capability-card-right capability='security' /> <!-- Links to src/local/capability/card-right.vue -->
              <local-capability-card-left capability='projects' /> <!-- Links to src/local/capability/card-left.vue -->
        </v-container>
      
        <v-container class=cont2 fluid>
              <local-capability-card-right capability='serverless' /> <!-- Links to src/local/capability/card-right.vue -->
              <local-capability-card-left capability='dayzero' /> <!-- Links to src/local/capability/card-left.vue -->
        </v-container>

        <v-container>
          <v-card />
        </v-container>

    </div>

</template>


<script>

  export default {
    name: 'Capability',
    data() {
      return {
        pagename: 'capabilitytitle'
      }
    }
  }
</script>


<style scoped>


.contmain {
  padding-bottom: 20px;
}

.background {
  background-color: var(--v-whiteblack-base);
  padding: 10 px;
  background-position: top center;
}

.header {
  background-color: var(--v-bgdark-base);
}

.component {
  background-color: transparent;
  margin: auto;
  width: 60%;
  padding: 10px;
}

.cont1 {
  height: 100%;
  background-image: url('../assets/images/capability/netcomm.jpeg');
}

.cont2 {
  height: 100%;
  background-position: bottom right;
}

.cont3 {
  height: 100%;
  background-image: url('../assets/images/capability/disc.jpeg');
}

</style>